import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import productData from "../Datas/productData";
import Header from "./Header";
import Footer from "./Footer";
import "../css/productdetails.css";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../Component/CartContext";

const ProductDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const product = productData.find((item) => item.id === parseInt(id));
  const [quantities, setQuantities] = useState("1");
  const { addToCart, updateQuantity } = useContext(CartContext);

  const handleAddToCart = () => {
    addToCart(product.id, quantities);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    navigate("/cartpage");
  };
  if (!product) {
    return <div>Product not found</div>;
  }

  const handleInputChange = (id, event) => {
    const newQuantity = parseInt(event.target.value, 10);
    if (!isNaN(newQuantity) && newQuantity >= 0) {
      setQuantities(event.target.value);
      updateQuantity(id, newQuantity);
    }
  };

  return (
    <div className="main-container">
      <Header />
      <div className="shopDiv">
        <div className="product-detail">
          <div className="product-images">
            <div className="carousel-container">
              <div className="carousel">
                <img src={product.images[0]} alt="" />
              </div>
            </div>
          </div>

          <div className="product-info">
            <div className="g-0">
              <div className="bg-quaternary">
                <h3>{product.name}</h3>
              </div>
            </div>
            <div className="div-two-price">
              <h4 className="stock-in">{product.price}</h4>
              <p>Words:{product.words}</p>
            </div>

            <div className="summary-container">
              <div className="template_content">
                <input
                  type="number"
                  value={quantities}
                  min="1"
                  onChange={(e) => handleInputChange(product.id, e)}
                  className="quantity-input22"
                />
                <div className="btnDiv">
                  <button className="add-to-cart" onClick={handleAddToCart}>
                    Add to Cart
                  </button>
                </div>
              </div>
              <div class="product_meta">
                <span class="sku_wrapper">
                  SKU:&nbsp;
                  <span class="sku" data-o_content="CWSSP">
                    CWSSP
                  </span>
                </span>
                &nbsp;
                <span class="posted_in">
                  Category:&nbsp;
                  <a
                    href="https://www.contentwritingshop.co.uk/product-category/content-writing"
                    rel="tag"
                  >
                    Content Writing
                  </a>
                </span>
              </div>
            </div>
          </div>

          <div className="sidebar">
            <div className="sidebar_inner">
              <h2>Recent Posts</h2>
              <ul>
                <li>
                  How To Write Excellent Content For Website, Blogs, or
                  Advertisements
                </li>
                <li>What Evergreen Content Is? & Why It Matters?</li>
                <li>Dont Worry About Duplicate Contents – Matt Cutt</li>
                <li>Why We Opt to Outsource In House Content Writing?</li>
                <li>
                  Content Writing Shop UK Launches Blog On Content Writing
                </li>
              </ul>
              <h2>Recent Comments</h2>
              <p>No comments to show.</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductDetail;
