import React from "react";
import "../css/footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faPhone } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <div className="bottom-container">
        <div className="botLeft">
          <div className="bot-bot">
            <div className="about-bot">
              <h4>About</h4>
              <p>
                We are leading provider of contents to digital agencies in UK.
                We are bespoke web content writing and marketing agency offering
                blog post , Articles, Web copy writing, Reviews, comments and
                press release writing.
              </p>
            </div>
            <div className="quick-link">
              <h4>Quick Links</h4>
              <ul>
                <li className="menu-item">
                  <Link to="/" className="homeToA">
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/about" className="homeToA">
                    About
                  </Link>
                </li>
                <li className="menu-item">
                  <Link to="/contact" className="homeToA">
                    Contact
                  </Link>
                </li>
                {/* <li className="menu-item">
                  <Link to="/particular" className="homeToA">
                    特定商取引法に基づく表記
                  </Link>
                </li> */}
              </ul>
            </div>
            <div className="contact-us">
              <h4>Contact Us</h4>
              <ul>
                <li className="li-svg">
                  <FontAwesomeIcon icon={faLocationDot} className="position" />
                  <p>86-90 Paul Street London EC2A 4NE</p>
                </li>
                <li className="li-svg">
                  <FontAwesomeIcon icon={faPhone} className="phone" />
                  07466 440 362
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="container clearfix">
              <div className="footer-info">
                Copyright 2011-2023 © Content Writing Shop
              </div>
              <img alt="" src="/head-foot/image.png" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
