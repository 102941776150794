import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/contact.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faLocationDot } from "@fortawesome/free-solid-svg-icons";

function Contact() {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");

  const handlePlaceOrder = () => {
    const newErrors = {};

    if (!email) newErrors.email = "Email is required";
    if (!firstName) newErrors.firstName = "First name is required";

    // setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      alert("Please fill all required fields");
    } else {
      alert("Send success!");
    }
  };
  return (
    <div className="main-container">
      <Header />

      <div className="ContactDiv">
        <div className="contact-inner">
          <div className="sticky_mobile">
            <img alt="" src="/main/contact-bac2.png" />
            <div className="our-contact">
              <h1>Contact Us</h1>
              <p>
                Contact us with your specific and prioritised content and we
                will be delighted to assist you with our fine quality of
                content.&nbsp;
              </p>
              <h3>Call: 07466 440 362</h3>
            </div>
          </div>

          <div className="line">
            <div className="line-p">
              <div className="line-p-inner">
                <div className="line-row">
                  <div className="line-name">
                    <input
                      size="40"
                      class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                      aria-required="true"
                      aria-invalid="false"
                      value={firstName}
                      type="text"
                      name="your-firstname"
                      placeholder="Your Name"
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                  <div className="line-name">
                    <input
                      size="40"
                      class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                      aria-required="true"
                      aria-invalid="false"
                      value={email}
                      type="email"
                      name="email"
                      placeholder="Your Email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="line-row">
                  <div className="line-name">
                    <textarea
                      size="40"
                      class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                      aria-required="true"
                      aria-invalid="false"
                      value=""
                      type="text"
                      placeholder="Your Message"
                    />
                  </div>
                </div>
                <input
                  className="has-spinner"
                  type="submit"
                  value="Send"
                  onClick={handlePlaceOrder}
                />
              </div>
            </div>
            <div class="et_pb_bottom_inside_divider"></div>
          </div>
          <div className="office-phone">
            <div className="office-inner">
              <div className="office-div">
                <img alt="" src="/main/position.png" />
                <h4>Our Office</h4>
                <p>
                  86-90 Paul Street, London
                  <br />
                  EC2A 4NE
                </p>
              </div>
              <div className="empty"></div>
              <div className="tool-div">
                <img alt="" src="/main/tool.png" />
                <h4>Phone</h4>
                <p>07466 440 362</p>
              </div>
            </div>
          </div>
          <div className="contact-start" style={{ marginTop: "16%" }}>
            <div className="contact-our">
              <h2>Contact Us</h2>
              <h4>
                <FontAwesomeIcon icon={faPhone} />
                07466 440 362
              </h4>
              <h4>
                <FontAwesomeIcon icon={faLocationDot} />
                86-90 Paul Street London EC2A 4NE
              </h4>
            </div>
            <div className="et_pb_column_7">
              <h2>Get Started</h2>
              <p>Welcome to the community of content.</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
