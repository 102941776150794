import React, { useContext, useState, useEffect } from "react";
import { CartContext } from "../Component/CartContext";
import "../css/checkout.css";
import productData from "../Datas/productData";
import Header from "./Header";
import Footer from "./Footer";
import {
  CitySelect,
  CountrySelect,
  StateSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import { useNavigate } from "react-router-dom";

const CheckOut = () => {
  const { cartItems, clearCart } = useContext(CartContext);
  const [country, setCountry] = useState("US");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [postcode, setPostcode] = useState("");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [countryId, setCountryId] = useState("0");
  const [stateId, setStateId] = useState(0);
  const [showStateCity, setShowStateCity] = useState(false);

  useEffect(() => {
    setShowStateCity(country === "China");
  }, [country]);

  const handlePlaceOrder = () => {
    const newErrors = {};

    if (!email) newErrors.email = "Email is required";
    if (!country) newErrors.country = "Country/Region is required";
    if (!phone) newErrors.phone = "Phone number is required";
    if (!firstName) newErrors.firstName = "First name is required";
    if (!lastName) newErrors.lastName = "Last name is required";
    if (!address) newErrors.address = "Street address is required";
    if (!city && showStateCity) newErrors.city = "Town / City is required";
    if (showStateCity && !stateId) newErrors.state = "State is required";
    if (!postcode) newErrors.postcode = "Postcode is required";

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      alert("Please fill all required fields");
    } else {
      alert("Order placed!");
      clearCart();
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      navigate("/paysuccess");
    }
  };

  const getProductById = (id) => {
    return productData.find((product) => product.id === id);
  };

  const calculateTotal = () => {
    let total = 0;
    cartItems.forEach((item) => {
      const product = getProductById(item.id);
      if (product) {
        const price = parseFloat(product.price.replace("£", ""));
        total += price * item.quantity;
      }
    });
    return total;
  };

  const calculateItemTotal = (itemId, quantity) => {
    const product = getProductById(itemId);
    if (!product) return 0;
    const price = parseFloat(product.price.replace("£", ""));
    return price * quantity; // 计算总价
  };
  return (
    <>
      <Header />
      <div className="main-containerCheck">
        <div className="check-custom-checkout-page">
          <div className="left">
            <div className="check-order-info">
              <h3>Billing details</h3>
              <form>
                <div className="firstLast">
                  <div className="form-group1 form-group-half1">
                    <label htmlFor="first-name">First name *</label>
                    <input
                      type="text"
                      id="first-name"
                      name="first-name"
                      value={firstName}
                      placeholder="First name *"
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                    />
                    {errors.firstName && (
                      <p className="error">{errors.firstName}</p>
                    )}
                  </div>
                  <div className="form-group1 form-group-half">
                    <label htmlFor="last-name">Last name *</label>
                    <input
                      type="text"
                      id="last-name"
                      name="last-name"
                      value={lastName}
                      placeholder="Last name *"
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    />
                    {errors.lastName && (
                      <p className="error">{errors.lastName}</p>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email address *</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    placeholder="Email address *"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  {errors.email && <p className="error">{errors.email}</p>}
                </div>
                <div className="form-group form-group-half">
                  <label htmlFor="company-name">Company name (optional)</label>
                  <input
                    type="text"
                    id="company-name"
                    name="company-name"
                    placeholder="Company name (optional)"
                  />
                </div>

                <div className="form-group form-group-half">
                  <label htmlFor="country">Country/Region *</label>
                  <CountrySelect
                    onChange={(e) => {
                      setCountry(e.name);
                      setCountryId(e.id);
                    }}
                    placeHolder="Select Country"
                  />
                  {errors.country && <p className="error">{errors.country}</p>}
                </div>
                <div className="form-group">
                  <label htmlFor="address">
                    House number and street name *
                  </label>
                  <input
                    type="text"
                    id="address"
                    name="address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    required
                    placeholder="House number and street name*"
                  />
                  {errors.address && <p className="error">{errors.address}</p>}

                  <input
                    type="text"
                    id="address2"
                    name="address2"
                    value={address}
                    className="twoAdd"
                    required
                    placeholder="Apartment, suite, unit, etc. (optional)"
                  />
                </div>
                <div className="form-group form-group-half">
                  <label htmlFor="city">Town / City *</label>
                  <input
                    type="text"
                    id="city"
                    name="city"
                    value={city}
                    placeholder="Town / City *"
                    onChange={(e) => setCity(e.target.value)}
                    required
                  />
                  {errors.city && <p className="error">{errors.city}</p>}
                </div>
                {showStateCity && (
                  <>
                    <div className="form-group form-group-half">
                      <label htmlFor="state">State *</label>
                      <StateSelect
                        countryid={countryId}
                        onChange={(e) => {
                          setStateId(e.id);
                        }}
                        placeHolder="Select State"
                      />
                      {errors.state && <p className="error">{errors.state}</p>}
                    </div>
                    <div className="form-group form-group-half">
                      <label htmlFor="city">City *</label>
                      <CitySelect
                        countryid={countryId}
                        stateid={stateId}
                        onChange={(e) => {
                          console.log(e);
                        }}
                        placeHolder="Select City"
                      />
                    </div>
                  </>
                )}
                <div className="form-group form-group-half">
                  <label htmlFor="postcode">Postcode *</label>
                  <input
                    type="text"
                    id="postcode"
                    placeholder="Postcode*"
                    onChange={(e) => setPostcode(e.target.value)}
                    name="postcode"
                    required
                  />
                  {errors.postcode && (
                    <p className="error">{errors.postcode}</p>
                  )}
                </div>

                <div className="form-group form-group-half">
                  <label htmlFor="phone">Phone *</label>
                  <input
                    type="phone"
                    id="phone"
                    name="phone"
                    value={phone}
                    placeholder="Phone*"
                    onChange={(e) => setPhone(e.target.value)}
                    required
                  />
                  {errors.phone && <p className="error">{errors.phone}</p>}
                </div>
              </form>
            </div>
          </div>

          <div className="right">
            <div className="check-order-summary">
              <h2>Your order</h2>
              <div className="check-order-details">
                <table className="check-cart-table">
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Subtotal</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cartItems
                      .filter((product) => product.id >= 1 && product.id <= 10)
                      .map((item, index) => {
                        const product = getProductById(item.id);
                        return (
                          <tr key={index}>
                            <td>{product.name}</td>
                            <td>
                              £
                              {calculateItemTotal(
                                item.id,
                                item.quantity
                              ).toFixed(2)}
                            </td>
                          </tr>
                        );
                      })}
                    <tr>
                      <th>Subtotal</th>
                      <th>£{calculateTotal().toFixed(2)}</th>
                    </tr>
                    <tr>
                      <th>Total(inc VAT)</th>
                      <th>£{calculateTotal().toFixed(2)}</th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <button
              className="check-checkout-button"
              onClick={handlePlaceOrder}
            >
              Pay Order
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CheckOut;
