const productData = [
  {
    id: 1,
    main: "Silver",
    name: "Silver Plan",
    price: "£30.000",
    words: "1000",
    pence: "3",
    mid: "/Entry Level",
    bot: [
      "100% Unique Content",
      "Fast Turnaround",
      "May Have Errors",
      "One Free Revision",
    ],
    images: ["/images/Silver-Plan.png"],
  },
  {
    id: 2,
    main: "Gold",
    name: "Gold Plan",
    price: "£40.000",
    words: "1000",
    pence: "4",
    mid: "/Professional",
    bot: [
      "Diversified Expertise",
      "100% Unique Error Free Content",
      "Persuasive & Well Researched",
      "2 Free Revisions Included",
    ],
    images: ["/images/Gold-Plan.png"],
  },
  {
    id: 3,
    main: "Platinum",
    name: "Platinum Plan",
    price: "£90.000",
    words: "1000",
    pence: "9",
    mid: "/Expert Level",
    bot: [
      "Highly Qualified UK Writers",
      "Extensive Experience & Vast Exposure",
      "Ideal for Detailed Jobs",
      "4 Free Revisions Included",
    ],
    images: ["/images/Platinum-Plan.png"],
  },
];

export default productData;
