import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/about.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFaceSmile,
  faUserTie,
  faSquareCheck,
  faDesktop,
  faPhone,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";

function About() {
  return (
    <div className="main-container">
      <Header />
      <div className="contactAll">
        <div className="twoDiv">
          <div className="et_pb_image_wrap">
            <img alt="" src="/main/write.jpg" />
          </div>
          <div className="et-last-child">
            <div className="layout_light">
              <div class="et_pb_text_inner">
                <h1>Content Writing Shop</h1>
              </div>
              <p>
                We are leading provider of contents to digital agencies in UK.
                We are bespoke web content writing and marketing agency offering
                blog post , Articles, Web copy writing, Reviews, comments and
                press release writing.
              </p>
              <h2>Our Team</h2>
              <p>
                Our all writers are based in United Kingdom and native English
                is their first language. We have writers having specialty in
                different fields like IT, SEO, home improvement, fashion and
                many more.
                <br />
                We have experienced content writers who have been working in
                this field since last many years and also some upcoming new
                aspiring college writers. We maintain a mix of new and old so
                that you get the benefit of experience, new thoughts, creativity
                and ingenuity.
                <br />
                Our team is been formed and managed by our team manager Aprajita
                Kohli who herself has done Journalism and PR from University Of
                Greenwich at London, United Kingdom and has also done Advance
                Information Technology course at University of London at London.
                Since she is based in United Kingdom itself she has been able to
                find the right team of people to do the work for us.
              </p>
            </div>
          </div>
        </div>
        <div className="et_pb_top_divider">
          <div className="et_pb_top_inside_divider"></div>
          <div className="et_pb_row_1">
            <div className="mode_passthrough">
              <FontAwesomeIcon icon={faFaceSmile} color="#0C71C3" />
              <h4>239 HAPPY CUSTOMERS</h4>
              <p>
                Customers are the focal point of all businesses It is our utmost
                priority that the goal and needs of the customers are taken care
                of in a proper manner.
              </p>
              <FontAwesomeIcon icon={faUserTie} color="#0C71C3" />
              <h4>24 EXPERT WRITERS</h4>
              <p>
                We have team of expert writers that are well versed in Content
                Writing and with different region writing skills with good
                vcabolary.
              </p>
            </div>
            <div className="mix_blend">
              <FontAwesomeIcon icon={faDesktop} color="#0C71C3" />
              <h4>2535 PUBLISHED CONTENTS</h4>
              <p>
                Our record says that we have generated 2535+ contents for our
                clients for various purposes that help them to acheive their
                goal.
              </p>
              <FontAwesomeIcon icon={faSquareCheck} color="#0C71C3" />
              <h4>680 PROJECTS COMPLETED</h4>
              <p>
                Customers are the focal point of all businesses It is our utmost
                priority that the goal and needs of the customers are taken care
                of in a proper manner.
              </p>
            </div>
          </div>
        </div>
        <div className="et_section_regular">
          <div className="et_pb_top_inside_divider2"></div>
          <div className="miss-our">
            <div className="miss-child">
              <h2>Our Mission</h2>
              <hr />
              <p>
                We are here to provide the quality of content,source of richness
                and purity of matter to client community present all over the
                world.
              </p>
              <button className="et_pb_button">Set Up a Consultation</button>
            </div>
            <div className="img-child">
              <img alt="" src="/main/t-img.png" />
            </div>
          </div>
        </div>
        <div className="contact-start">
          <div className="contact-our">
            <h2>Contact Us</h2>
            <h4>
              <FontAwesomeIcon icon={faPhone} />
              07466 440 362
            </h4>
            <h4>
              <FontAwesomeIcon icon={faLocationDot} />
              86-90 Paul Street London EC2A 4NE
            </h4>
          </div>
          <div className="et_pb_column_7">
            <h2>Get Started</h2>
            <p>Welcome to the community of content.</p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default About;
