import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/main.css";
import productData from "../Datas/productData";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faLocationDot } from "@fortawesome/free-solid-svg-icons";

function Main() {
  const handleNavigate = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="main-container">
      <Header />
      <div className="mainBox">
        <div className="mainBox">
          <div className="container-fluid">
            <div className="sticky_mobile1">
              <img alt="" src="/main/contact-bac2.png" />
              <div className="our-contact1">
                <h1>
                  Content
                  <br /> Writing Shop
                </h1>
                <p>Hit Bulls Eye With Precise Writing</p>
              </div>
              <img
                alt=""
                src="/main/book.png"
                style={{
                  width: "400px",
                  height: "400px",
                  marginLeft: "-41%",
                  marginTop: "5%",
                }}
              />
            </div>

            <div className="top-container">
              <div className="product-grid">
                {productData.map((brand) => (
                  <div
                    className="product-category"
                    key={brand.id}
                    style={{
                      marginTop: brand.id === 2 ? "-4%" : "0",
                      marginRight: brand.id === 1 ? "-4%" : "0",
                      marginLeft: brand.id === 3 ? "-4%" : "0",
                      boxShadow:
                        brand.id === 2 ? " 0 0 12px rgba(0, 0, 0, .1)" : "none",
                      zIndex: brand.id === 3 || brand.id === 1 ? "1" : "10",
                    }}
                  >
                    <div className="image-container">
                      <div
                        className="et_pb_pricing_heading"
                        style={{
                          backgroundColor:
                            brand.id === 2 ? "#00b4ff" : "#003570",
                        }}
                      >
                        <h2>{brand.main}</h2>
                        <span>{brand.pence}&nbsp;Pence Per Word</span>
                      </div>
                      <div className="et_pb_pricing_content_top">
                        {brand.mid}
                      </div>
                      <div className="et_pb_pricing_content">
                        <ul className="et_pb_pricing">
                          {brand.bot.map((bot, index) => (
                            <li key={{ index }}>{bot}</li>
                          ))}
                        </ul>
                      </div>
                      <div className="et_pb_button_wrapper">
                        <Link
                          to={`/shopDetails/${brand.id}`}
                          onClick={handleNavigate}
                        >
                          <button>Buy Now</button>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="contact-start" style={{ marginTop: "16%" }}>
              <div className="contact-our">
                <h2>Contact Us</h2>
                <h4>
                  <FontAwesomeIcon icon={faPhone} />
                  07466 440 362
                </h4>
                <h4>
                  <FontAwesomeIcon icon={faLocationDot} />
                  86-90 Paul Street London EC2A 4NE
                </h4>
              </div>
              <div className="et_pb_column_7">
                <h2>Get Started</h2>
                <p>Welcome to the community of content.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Main;
