import React, { useContext, useState } from "react";
import { CartContext } from "../Component/CartContext";
import productData from "../Datas/productData";
import Header from "./Header";
import Footer from "./Footer";
import "../css/cartpage.css";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

const StyledTableContainer = styled(TableContainer)({
  minWidth: 650,
});

const StyledImage = styled("img")({
  width: 112,
  height: 124,
  objectFit: "cover",
});

const StyledTableCell = styled(TableCell)({
  display: "flex",
  alignItems: "center",
  verticalAlign: "middle",
  fontSize: "16px",
  borderRight: "1px dotted #ccc",
});

const CartPage = () => {
  const { cartItems, removeFromCart, updateQuantity } = useContext(CartContext);
  const navigate = useNavigate();

  const getProductById = (id) => {
    return productData.find((product) => product.id === id);
  };

  const toCheckOut = () => {
    if (cartItems.length === 0) {
      alert("Please add items to your cart before proceeding to checkout.");
      return;
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    navigate("/checkout");
  };

  const calculateTotal = () => {
    let total = 0;
    cartItems.forEach((item) => {
      const product = getProductById(item.id);
      if (product) {
        const price = parseFloat(product.price.replace("£", ""));
        total += price * item.quantity;
      }
    });
    return total;
  };

  const calculateItemTotal = (itemId, quantity) => {
    const product = getProductById(itemId);
    if (!product) return 0;
    const price = parseFloat(product.price.replace("£", ""));
    return price * quantity; // 计算总价
  };

  const handleInputChange = (id, event) => {
    const newQuantity = parseInt(event.target.value, 10);
    if (!isNaN(newQuantity) && newQuantity >= 0) {
      updateQuantity(id, newQuantity);
    }
  };

  return (
    <>
      <div className="main-container">
        <Header />
        <div className="cart-page">
          <div className="cart-total">
            <div className="cart-items">
              <h1>Cart</h1>
              <div className="woocommerce-notices-wrapper">
                <div className="woocommerce-message" role="alert">
                  “Silver Plan” has been added to your cart.
                  <button href="/" tabindex="1">
                    Continue shopping
                  </button>
                </div>
              </div>
              {cartItems.length === 0 ? (
                <h3 style={{ color: "black", textAlign: "center" }}>
                  Your Cart is Empty!
                </h3>
              ) : (
                <StyledTableContainer component={Paper}>
                  <Table aria-label="shopping cart table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Product</TableCell>
                        <TableCell align="left">Price</TableCell>
                        <TableCell align="center">Quantity</TableCell>
                        <TableCell align="left">Subtotal</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {cartItems
                        .filter((product) => product.id >= 1 && product.id <= 3)
                        .map((item, index) => {
                          const product = getProductById(item.id);
                          return (
                            <TableRow key={index}>
                              <StyledTableCell component="th" scope="row">
                                <a
                                  onClick={() =>
                                    removeFromCart(item.id, item.weight)
                                  }
                                  style={{
                                    cursor: "context-menu",
                                    textDecoration: "underline",
                                    fontFamily: "Libre Baskerville",
                                    fontSize: "18px",
                                    fontWeight: "600",
                                    marginRight: "10%",
                                  }}
                                >
                                  <FontAwesomeIcon icon={faXmark} color="red" />
                                </a>
                                <StyledImage
                                  src={product.images[0]}
                                  alt="Product"
                                  style={{
                                    paddingRight: "30px",
                                  }}
                                />
                                <div
                                  style={{
                                    display: "grid",
                                  }}
                                >
                                  {product.name}-{product.words}
                                </div>
                              </StyledTableCell>
                              <TableCell
                                align="left"
                                style={{
                                  verticalAlign: "middle",
                                  borderRight: "1px dotted #ccc",
                                }}
                              >
                                {product.price}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  verticalAlign: "middle",
                                }}
                              >
                                <input
                                  type="number"
                                  value={item.quantity || 1}
                                  className="cartInput"
                                  onChange={(e) =>
                                    handleInputChange(item.id, e)
                                  }
                                  min="0"
                                />
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ verticalAlign: "middle" }}
                              >
                                £
                                {calculateItemTotal(
                                  item.id,
                                  item.quantity
                                ).toFixed(3)}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </StyledTableContainer>
              )}
              {cartItems.length > 0 && (
                <div className="cart-summary">
                  <div className="rightSum">
                    <h2 className="basketDiv">Cart totals</h2>

                    <table className="summary-details">
                      <tbody className="sumTbody">
                        <tr className="sumTr">
                          <th className="subtotalTh">Subtotal</th>
                          <th className="subtotalTxt">
                            £{calculateTotal().toFixed(3)}
                          </th>
                        </tr>
                        <tr className="sumTr">
                          <th className="subtotalTh">Total</th>
                          <th className="subtotalTxt">
                            £{calculateTotal().toFixed(3)}
                          </th>
                        </tr>
                      </tbody>
                    </table>
                    <button className="checkout-button" onClick={toCheckOut}>
                      Proceed to checkout
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div
              className="sidebar"
              style={{ marginLeft: "-14%", paddingTop: "8%" }}
            >
              <div className="sidebar_inner">
                <h2>Recent Posts</h2>
                <ul>
                  <li>
                    How To Write Excellent Content For Website, Blogs, or
                    Advertisements
                  </li>
                  <li>What Evergreen Content Is? & Why It Matters?</li>
                  <li>Dont Worry About Duplicate Contents – Matt Cutt</li>
                  <li>Why We Opt to Outsource In House Content Writing?</li>
                  <li>
                    Content Writing Shop UK Launches Blog On Content Writing
                  </li>
                </ul>
                <h2>Recent Comments</h2>
                <p>No comments to show.</p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default CartPage;
